import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { provideEcharts } from 'ngx-echarts';
import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { MapChartComponent } from '../../shared/shared-components/charts/map-chart/map-chart.component';
import { PieChartComponent } from '../../shared/shared-components/charts/pie-chart/pie-chart.component';
import { ICityListItemModel } from '../../models/country-code.model';
import { CityListViewComponent } from '../../shared/shared-components/city-list-view/city-list-view.component';
import { ISocialListItemModel } from '../../models';
import { SocialListViewComponent } from '../../shared/shared-components/social-list-view/social-list-view.component';
@Component({
  selector: 'app-statics',
  standalone: true,
  imports: [
    CommonModule,
    DataViewModule,
    ButtonModule,
    MapChartComponent,
    PieChartComponent,
    CityListViewComponent,
    SocialListViewComponent,
  ],
  providers: [provideEcharts()],
  templateUrl: './statics.component.html',
  styleUrl: './statics.component.scss',
})
export class StaticsComponent implements OnInit {
  pieChartDeviceOptions!: EChartsOption;
  pieChartBrowserOptions!: EChartsOption;

  cities: ICityListItemModel[] = [
    { count: 3109, CITY: 'London', countryCode: 'EG' },
    { count: 3109, CITY: 'London', countryCode: 'AU' },
    { count: 3109, CITY: 'London', countryCode: 'AU' },
    { count: 3109, CITY: 'London', countryCode: 'AU' },
    { count: 3109, CITY: 'London', countryCode: 'EG' },
    { count: 3109, CITY: 'London', countryCode: 'AU' },
    { count: 3109, CITY: 'London', countryCode: 'AU' },
    { count: 3109, CITY: 'London', countryCode: 'AU' },
  ];

  socialsData: ISocialListItemModel[] = [
    { count: 3109, social: 'Facebook', imgUrl: 'facebook.svg' },
    { count: 3109, social: 'Tiktok', imgUrl: 'tiktok.svg' },
    { count: 3109, social: 'Instagram', imgUrl: 'instagram.svg' },
    { count: 3109, social: 'Telegram', imgUrl: 'telegram.svg' },
    { count: 3109, social: 'Threads', imgUrl: 'threads.svg' },
  ];

  ngOnInit(): void {
    const charDevicetData = [
      { value: 1048, name: 'Desktop' },
      { value: 735, name: 'Mobile' },
      { value: 580, name: 'Tablet' },
    ];
    const charBrowsertData = [
      { value: 1048, name: 'Chrome' },
      { value: 735, name: 'Safari' },
      { value: 580, name: 'Microsoft' },
      { value: 580, name: 'Firefox' },
    ];
    const totalDevice = charDevicetData.reduce((sum, item) => sum + item.value, 0);
    const totalBrowser = charBrowsertData.reduce((sum, item) => sum + item.value, 0);

    this.pieChartDeviceOptions = {
      title: {
        text: 'Device Type',
        textStyle: {
          color: '#344f60',
        },
      },
      tooltip: {
        trigger: 'item',
      },
      legend: [
        {
          itemGap: 18,
          orient: 'vertical',
          left: 'left',
          bottom: 30,
          align: 'auto',
          formatter: function (name) {
            return name;
          },
          textStyle: {
            fontWeight: 500,
            color: '#344F60',
            fontSize: 16,
          },
        },
        {
          itemGap: 18,
          orient: 'vertical',
          left: 'right',
          bottom: 30,
          formatter: function (name) {
            const item = charDevicetData.find((d) => d.name === name);
            if (item) {
              const percentage = ((item.value / totalDevice) * 100).toFixed(2);
              return `${Math.ceil(parseInt(percentage))}%`;
            }
            return name;
          },
          textStyle: {
            fontWeight: 700,
            color: '#344F60',
            fontSize: 16,
          },
          icon: 'none',
        },
      ],
      series: [
        {
          height: '75%',
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: charDevicetData,
        },
      ],
    };
    this.pieChartBrowserOptions = {
      title: {
        text: 'Browser Type',
        textStyle: {
          color: '#344f60',
        },
      },
      tooltip: {
        trigger: 'item',
      },
      legend: [
        {
          itemGap: 18,
          orient: 'vertical',
          left: 'left',
          bottom: 0,
          formatter: function (name) {
            return name;
          },
          textStyle: {
            fontWeight: 500,
            color: '#344F60',
            fontSize: 16,
          },
        },
        {
          itemGap: 18,
          orient: 'vertical',
          left: 'right',
          bottom: 0,
          formatter: function (name) {
            const item = charBrowsertData.find((d) => d.name === name);
            if (item) {
              const percentage = ((item.value / totalBrowser) * 100).toFixed(2);
              return `${Math.ceil(parseInt(percentage))}%`;
            }
            return name;
          },
          textStyle: {
            fontWeight: 700,
            color: '#344F60',
            fontSize: 16,
          },
          icon: 'none',
        },
      ],
      series: [
        {
          height: '75%',
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: charBrowsertData,
        },
      ],
    };
  }
}
