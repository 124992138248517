import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  IApiResponseWithData,
  IDashboard,
  IApiResponse,
  IDashboardPayload,
  IFileData,
  IFileLinksPayload,
  IFilesPayload,
  IFileTrimmedLinksData,
  ITrimmedLinksData,
  ITrimmedLinksPayloads,
  ITrimSingleLink,
} from '../models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OtrimApi {
  otrim_ApiUrl = environment.otrimApiUrl;
  bearerToken = environment.bearerToken;

  private httpClient = inject(HttpClient);
  private headers = new HttpHeaders({
    accept: '*/*',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${this.bearerToken}`,
  });

  private readonly options = {
    headers: this.headers,
    withCredentials: true,
  };

  insertCustomTrim(payload: ITrimSingleLink): Observable<Object> {
    return this.httpClient.post(this.otrim_ApiUrl + 'insertCustomizedURL', payload, this.options);
  }

  getDashboardData(payload: IDashboardPayload): Observable<IApiResponse<IDashboard>> {
    return this.httpClient
      .post(this.otrim_ApiUrl + 'dashboard', payload, this.options)
      .pipe(map((response) => response as IApiResponse<IDashboard>));
  }

  getTrimAnalytics(payload: ITrimmedLinksPayloads): Observable<IApiResponse<ITrimmedLinksData>> {
    return this.httpClient
      .post(this.otrim_ApiUrl + 'getTrimUrlDataServerSide', payload, this.options)
      .pipe(map((response) => response as IApiResponse<ITrimmedLinksData>));
  }

  getAllFilesData(payload: IFilesPayload): Observable<IApiResponse<IFileData>> {
    return this.httpClient
      .post(this.otrim_ApiUrl + 'getAllFilesData', payload, this.options)
      .pipe(map((response) => response as IApiResponse<IFileData>));
  }

  fetchRecordByFile(payload: IFileLinksPayload): Observable<IApiResponseWithData<IFileTrimmedLinksData>> {
    return this.httpClient
      .post(this.otrim_ApiUrl + 'getAllRecordsByFileId', payload, this.options)
      .pipe(map((response) => response as IApiResponseWithData<IFileTrimmedLinksData>));
  }

  searchAlias(payload: any) {
    return this.httpClient.post(this.otrim_ApiUrl + 'searchAliasName', payload, this.options);
  }

  getTrimUrlsCsv(payload: any) {
    return this.httpClient.post(this.otrim_ApiUrl + 'exporttrimUrlRecordcsvReport', payload, {
      ...this.options,
      responseType: 'blob',
    });
  }

  getSampleCsv(payload: any) {
    return this.httpClient.post(this.otrim_ApiUrl + 'download/csvSample/', payload, {
      ...this.options,
      responseType: 'blob',
    });
  }

  getDashboardCsv(payload: any) {
    return this.httpClient.post(this.otrim_ApiUrl + 'exportDashboardRecordcsvReport', payload, {
      ...this.options,
      responseType: 'blob',
    });
  }

  deleteFile(payload: any) {
    payload.toString().replace(/\//g, '_').replace(/\+/g, '-');
    return this.httpClient.delete(this.otrim_ApiUrl + 'deleteFile/' + payload, this.options);
  }

  fetchSingleFile(payload: any) {
    payload.toString().replace(/\//g, '_').replace(/\+/g, '-');
    return this.httpClient.get(this.otrim_ApiUrl + 'trimUrlRecordcsvReport/' + payload, {
      ...this.options,
      responseType: 'blob',
    });
  }

  fileUpload(url: any, params: any) {
    return this.httpClient.post(this.otrim_ApiUrl + url, params);
  }

  fileName(url: any, params: any) {
    return this.httpClient.post(this.otrim_ApiUrl + url, params);
  }

  insertTrim(payload: any) {
    return this.httpClient.post(this.otrim_ApiUrl + 'insertURL', payload, this.options);
  }

  updateTrim(payload: any) {
    return this.httpClient.put(this.otrim_ApiUrl + 'updateTrimUrl', payload, this.options);
  }

  spamLinks(payload: any) {
    return this.httpClient.post(this.otrim_ApiUrl + 'insertSpamURL', payload, this.options);
  }
}
