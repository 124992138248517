import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';
import { faEllipsis, faEye, faFile, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { OtrimFacade } from '../../services/otrim.facade';
import { IFileData } from '../../models/files.model';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-file-table',
  standalone: true,
  imports: [TableModule, FontAwesomeModule, CommonModule, ContextMenuModule],
  templateUrl: './file-table.component.html',
  styleUrl: './file-table.component.scss',
})
export class FileTableComponent implements OnInit {
  @Input() selectedFile!: IFileData;

  @Output() selectedFileChange = new EventEmitter<IFileData>();

  contextMenuItems!: MenuItem[];
  fileInfo: IFileData | null = null;
  faQrcode = faEye;
  faEllipsis = faEllipsis;
  faFile = faFile;

  private otrimFacade = inject(OtrimFacade);
  filesData: IFileData[] = [];
  isShowFileModal: boolean = false;

  setSelectedItem(newItem: IFileData) {
    this.fileInfo = newItem;
    this.isShowFileModal = true;
    this.selectedFileChange.emit(newItem);
  }

  ngOnInit() {
    const params = {
      pageSize: 50,
      pageIndex: 0,
      searchKey: '',
      orderBy: 'asc',
    };

    this.otrimFacade.getAllFilesData(params).subscribe((res: any) => {
      this.filesData = res?.result?.data;
    });

    this.contextMenuItems = [
      { label: 'Download', command: (event) => {} },
      { label: 'Delete', command: (event) => {} },
    ];
  }
}
