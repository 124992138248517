import { inject, Injectable } from '@angular/core';
import { OtrimState } from './otrim.state';
import { OtrimApi } from './otrim.api';
import { Observable } from 'rxjs';
import {
  IFileData,
  IFileLinksPayload,
  IFilesPayload,
  IFileTrimmedLinksData,
  IApiResponse,
  IApiResponseWithData,
  ITrimmedLinksData,
  ITrimmedLinksPayloads,
  ITrimSingleLink,
  IDashboard,
  IDashboardPayload,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class OtrimFacade {
  private otrimApi = inject(OtrimApi);
  private otrimState = inject(OtrimState);

  trimSingleLink(payload: ITrimSingleLink): Observable<Object> {
    return this.otrimApi.insertCustomTrim(payload);
  }

  getDashboardData(payload: IDashboardPayload): Observable<IApiResponse<IDashboard>> {
    return this.otrimApi.getDashboardData(payload);
  }

  setDashboardStatistics(stat: IDashboard): void {
    this.otrimState.setDashboardStatistics(stat);
  }

  getDashboardStatistics(): Observable<IDashboard | null> {
    return this.otrimState.getDashboardStatistics();
  }

  getTrimmedLinks(payload: ITrimmedLinksPayloads): Observable<IApiResponse<ITrimmedLinksData>> {
    return this.otrimApi.getTrimAnalytics(payload);
  }

  getAllFilesData(payload: IFilesPayload): Observable<IApiResponse<IFileData>> {
    return this.otrimApi.getAllFilesData(payload);
  }

  getFileLinks(payload: IFileLinksPayload): Observable<IApiResponseWithData<IFileTrimmedLinksData>> {
    return this.otrimApi.fetchRecordByFile(payload);
  }
}
