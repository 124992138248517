import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { TableModule } from 'primeng/table';
import { faEllipsis, faLineChart, faQrcode, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { OtrimFacade } from '../../services/otrim.facade';
import { ITrimmedLinksData } from '../../models/trim.model';
import { environment } from '../../../environments/environment';
import { RouterLink } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-url-table',
  standalone: true,
  imports: [TableModule, FontAwesomeModule, CommonModule, RouterLink, TooltipModule, DropdownModule, ContextMenuModule],
  templateUrl: './url-table.component.html',
  styleUrl: './url-table.component.scss',
})
export class UrlTableComponent implements OnInit {
  @Input() selectedItem!: ITrimmedLinksData;
  @Output() selectedItemChange = new EventEmitter<any>();

  contextMenuItems!: MenuItem[];
  faQrcode = faQrcode;
  faEllipsis = faEllipsis;
  faLineChart = faLineChart;
  faShare = faShare;
  trimmedLinks: ITrimmedLinksData[] = [];

  private otrimFacade = inject(OtrimFacade);

  ngOnInit() {
    const params = {
      fromDate: '',
      toDate: '',
    };

    this.otrimFacade.getTrimmedLinks(params).subscribe((res: any) => {
      this.trimmedLinks = res?.result?.data;
    });

    this.contextMenuItems = [
      { label: 'Copy', command: (event) => {} },
      { label: 'Edit', command: (event) => {} },
      { label: 'Delete', command: (event) => {} },
    ];
  }

  displayMessage(): void {
    console.log('clicked');
  }

  setSelectedItem(newItem: ITrimmedLinksData) {
    this.selectedItemChange.emit(newItem);
  }

  protected readonly environment = environment;
}
