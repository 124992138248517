import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { TableModule } from 'primeng/table';
import { AnalyticsCardComponent } from '../../shared/shared-components/analytics-card/analytics-card-component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { StaticsComponent } from '../../components/Statics/statics.component';
import { faCrown, faLink, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { MenuItem } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-link',
  standalone: true,
  imports: [
    FontAwesomeModule,
    TableModule,
    ButtonModule,
    DividerModule,
    CommonModule,
    AnalyticsCardComponent,
    BreadcrumbModule,
    StaticsComponent,
    CalendarModule,
    FormsModule,
  ],
  templateUrl: './link.component.html',
  styleUrl: './link.component.scss',
})
export class LinkComponent {
  date!: Date;

  analyticsCards = [
    {
      id: 2,
      header: 'Total clicks',
      data: 3128,
      icon: faLink,
      background: 'linear-gradient(180deg, rgba(224, 250, 255, 1) 0%, rgba(176, 230, 255, 0.67) 100%)',
      iconColor: '#00ACFB',
    },
    {
      id: 3,
      header: 'Unique clicks',
      data: 713,
      icon: faCrown,
      background: 'linear-gradient(180deg, rgba(224, 232, 255, 1) 0%, rgba(177, 176, 255, 0.67) 100%)',
      iconColor: '#2F20C6',
    },
    {
      id: 5,
      header: 'QR accessed',
      data: 4,
      icon: faQrcode,
      background: 'linear-gradient(180deg, rgba(233, 224, 255, 1) 0%, rgba(240, 176, 255, 0.67) 100%)',
      iconColor: '#7E11BA',
    },
  ];
  breadcrumbItems: MenuItem[] = [{ label: 'Main Screen', routerLink: '/', isRoot: true }, { label: 'temp' }];
}
