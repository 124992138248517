<p-dataView #dv [value]="cities" class="city-list-view__container">
  <ng-template pTemplate="list" let-cities>
    <div class="grid grid-nogutter mt-2">
      @for (city of cities; track city.id) {
        <div class="col-12 city__container">
          <div class="flex justify-content-between align-items-center">
            <div class="flex gap-2 align-items-center my-2">
              <div>
                <img
                  class="w-3rem h-3rem border-circle bg-cover flag-img"
                  [alt]="city.CITY"
                  src="{{ countryFlagUrl + city.countryCode }}.svg"
                />
              </div>
              <span class="text-lg">{{ city.CITY }}</span>
            </div>

            <span class="text-primary text-lg font-bold">{{ city.count }}</span>
          </div>
        </div>
      }
    </div>
  </ng-template>
</p-dataView>
