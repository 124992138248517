import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { TRIM_DROPDOWN_OPTIONS } from '../../shared/constants';
@Component({
  selector: 'app-table-options',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    RadioButtonModule,
    CommonModule,
    CalendarModule,
    InputGroupModule,
    InputGroupAddonModule,
    FormsModule,
    InputIconModule,
    IconFieldModule,
  ],
  templateUrl: './table-options.component.html',
  styleUrl: './table-options.component.scss',
})
export class TableOptionsComponent {
  @Input() selectedOption: any;

  @Output() selectedOptionChange = new EventEmitter<any>();

  searchValue: string = '';
  date!: Date;

  getOptionsArray() {
    return Object.values(TRIM_DROPDOWN_OPTIONS);
  }

  onOptionChange(newValue: any): void {
    this.selectedOptionChange.emit(newValue);
  }
}
