<div class="p-4 border-round-md short-url__container">
  <form [formGroup]="shortUrlForm" (ngSubmit)="onSubmit()">
    <div>
      <div class="field">
        <label for="url-for-shortening" class="text-sm font-medium pl-2">URL for shortening</label>
        <input
          id="url-for-shortening"
          formControlName="urlForShortening"
          type="text"
          class="border-round-xl w-full"
          placeholder="Enter URL to be shortened"
        />
      </div>
      <div class="formgrid grid flex align-items-center">
        <div class="field col pr-2">
          <label for="custom-name" class="text-sm font-medium pl-2">Custom name</label>
          <input
            [disabled]="true"
            id="custom-name"
            formControlName="customName"
            type="text"
            class="border-round-xl w-full"
            placeholder="Enter custom name"
          />
        </div>
        <div class="field col pr-2">
          <label for="short-url-preview" class="text-sm font-medium pl-2">Short URL preview</label>
          <input
            id="short-url-preview"
            formControlName="shortUrlPreview"
            type="text"
            class="border-round-xl w-full"
            placeholder="https://Otrim.com/"
          />
        </div>
        <div class="pt-2"><span class="text-base font-medium">/</span></div>
        <div class="field col pl-3\2">
          <label for="alias-name" class="text-sm font-medium pl-2">Alias name</label>
          <input
            id="alias-name"
            formControlName="aliasName"
            type="text"
            class="border-round-xl p-2 w-full"
            placeholder="Enter Alias name"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-content-between align-items-center mt-3">
      <div class="font-semibold upgrade-link flex gap-2 align-items-center">
        <fa-icon [icon]="faCrown"></fa-icon>
        <p class="my-0">
          Need to shorten multiple links at once?
          <a href="">Click here</a>
        </p>
      </div>
      <p-button label="Trim Link" type="submit" severity="success" [loading]="isLoading" [disabled]="isLoading" />
    </div>
  </form>
</div>
