import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IDashboard } from '../models/dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class OtrimState {
  dashboardStatistics = new BehaviorSubject<IDashboard | null>(null);

  setDashboardStatistics(stat: IDashboard) {
    this.dashboardStatistics.next(stat);
  }

  getDashboardStatistics(): Observable<IDashboard | null> {
    return this.dashboardStatistics.asObservable();
  }
}
