import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';

import worldMap from '../../../../../assets/geoMap.json';
import worldMapWithCentroids from '../../../../../assets/worldGeo.json';

import { type EChartsOption } from 'echarts';
import * as echarts from 'echarts';

@Component({
  selector: 'app-map-chart',
  standalone: true,
  imports: [NgxEchartsDirective],
  templateUrl: './map-chart.component.html',
  styleUrls: ['./map-chart.component.scss'],
  providers: [provideEcharts()],
})
export class MapChartComponent implements OnInit, AfterViewInit {
  countryData = [
    { name: 'Canada', countryCode: 'CA', value: 120 },
    { name: 'Russia', countryCode: 'RU', value: 1225 },
    { name: 'Palestine', countryCode: 'PS', value: 5600 },
  ];

  mapOptions: EChartsOption = {};
  countriesDataWithCentroIds = this.countryData.map(({ name, value, countryCode }) => ({
    name,
    value,
    coord: worldMapWithCentroids.features.find(
      ({ properties: { COUNTRY, ISO } }) => COUNTRY === name || ISO === countryCode
    )?.geometry.coordinates,
  }));

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initChart(); // Initialize chart only after the view is fully initialized
    }, 0);
  }

  initChart(): void {
    const chartDom = document.getElementById('map-chart'); // Ensure that the DOM element exists

    // Check if chartDom is null or undefined
    if (!chartDom) {
      console.error('Chart container element not found.');
      return; // Exit if the chart container is not found
    }

    echarts.registerMap('world', worldMap as any);

    this.mapOptions = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        backgroundColor: '#3E4756',
        borderColor: 'transparent',
        textStyle: { color: '#FFFFFF' },
        position: 'bottom',
        transitionDuration: 0.2,
        formatter: (mapOptions: any) => {
          let value = mapOptions.value;
          if (isNaN(value)) {
            value = 'No data available';
          } else {
            value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '');
          }
          return `${mapOptions.name}  <b>${value}</b>`;
        },
      },
      series: [
        {
          name: '',
          type: 'map',
          map: 'world',
          emphasis: {
            label: {
              show: false,
            },
            itemStyle: {
              areaColor: '#00ACFB', //region color on hover
            },
          },
          markPoint: {
            symbol: 'circle', // Use 'circle' for a simple circle
            symbolSize: 15, // Size of the circle
            itemStyle: {
              color: '#00ACFB', // Circle color
            },
            emphasis: {
              itemStyle: {
                color: '#FFFFFF', // circle color on hover
                borderWidth: 1,
              },
            },
            label: {
              show: false,
            },
            data: this.countriesDataWithCentroIds,
          },
          data: this.countryData,
        },
      ],
    };

    // Initialize chart only if chartDom exists
    const chartInstance = echarts.init(chartDom);

    // Add event listener for mouseover on the markPoint (circle)
    chartInstance.on('mouseover', ({ name: countryName, componentType }: echarts.ECElementEvent) => {
      if (componentType !== 'markPoint') {
        return;
      }
      // Use dispatchAction to highlight the corresponding region
      chartInstance.dispatchAction({
        type: 'highlight',
        name: countryName, // Name of the country/region to highlight
      });
    });

    // Add event listener for mouseout (reset hover state)
    chartInstance.on('mouseout', ({ name: countryName, componentType }: echarts.ECElementEvent) => {
      if (componentType !== 'markPoint') {
        return;
      }
      // Use dispatchAction to remove the highlight (reset hover state)
      chartInstance.dispatchAction({
        type: 'downplay', // This will reset the hover state
        name: countryName,
      });
    });
  }
}
