import { Component } from '@angular/core';
import { faCrown, faLink, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { CommonModule } from '@angular/common';
import { ShortUrlComponent } from '../../components/short-url/short-url-component';
import { AnalyticsCardComponent } from '../../shared/shared-components/analytics-card/analytics-card-component';
import { TableOptionsComponent } from '../../components/table-options/table-options.component';
import { UrlTableComponent } from '../../components/url-table/url-table.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { FileTableComponent } from '../../components/file-table/file-table.component';
import { StaticsComponent } from '../../components/Statics/statics.component';
import { ITrimDropdownOption } from '../../models';
import { TRIM_DROPDOWN_OPTIONS } from '../../shared/constants/index';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    FontAwesomeModule,
    TableModule,
    ButtonModule,
    DividerModule,
    CommonModule,
    ShortUrlComponent,
    AnalyticsCardComponent,
    TableOptionsComponent,
    UrlTableComponent,
    BreadcrumbModule,
    StaticsComponent,
    FileTableComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  showList = true;

  analyticsCards = [
    {
      id: 1,
      header: 'Short URLs',
      data: 114,
      icon: faLink,
      background: 'linear-gradient(180deg, rgba(224, 255, 237, 1) 0%, rgba(245, 255, 176, 0.67) 100%)',
      iconColor: '#1A384B',
    },
    {
      id: 2,
      header: 'Total clicks',
      data: 3128,
      icon: faLink,
      background: 'linear-gradient(180deg, rgba(224, 250, 255, 1) 0%, rgba(176, 230, 255, 0.67) 100%)',
      iconColor: '#00ACFB',
    },
    {
      id: 3,
      header: 'Unique clicks',
      data: 713,
      icon: faCrown,
      background: 'linear-gradient(180deg, rgba(224, 232, 255, 1) 0%, rgba(177, 176, 255, 0.67) 100%)',
      iconColor: '#2F20C6',
    },
    {
      id: 4,
      header: 'Broken links',
      data: 12,
      icon: faLink,
      background: 'linear-gradient(180deg, rgba(255, 224, 224, 1) 0%, rgba(255, 176, 176, 0.67) 100%)',
      iconColor: '#B30000',
    },
    {
      id: 5,
      header: 'QR accessed',
      data: 4,
      icon: faQrcode,
      background: 'linear-gradient(180deg, rgba(233, 224, 255, 1) 0%, rgba(240, 176, 255, 0.67) 100%)',
      iconColor: '#7E11BA',
    },
  ];

  selectedOption: ITrimDropdownOption = TRIM_DROPDOWN_OPTIONS.ALL_LINK;
  selecteOptions = TRIM_DROPDOWN_OPTIONS;

  onTabChange(show: boolean) {
    this.showList = show;
  }
}
