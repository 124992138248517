import { Component, inject, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { OtrimFacade } from '../../services/otrim.facade';
@Component({
  selector: 'app-short-url-component',
  standalone: true,
  imports: [ButtonModule, FontAwesomeModule, ReactiveFormsModule, CommonModule, InputTextModule],
  templateUrl: './short-url-component.html',
  styleUrl: './short-url-component.scss',
})
export class ShortUrlComponent implements OnInit {
  faCrown = faCrown;
  shortUrlForm!: FormGroup;
  isLoading: boolean = false;

  private fb = inject(FormBuilder);
  private otrimFacade = inject(OtrimFacade);

  ngOnInit(): void {
    this.shortUrlForm = this.fb.group({
      urlForShortening: ['', [Validators.required, Validators.maxLength(2000)]],
      customName: ['', [Validators.minLength(3), Validators.maxLength(30)]],
      aliasName: ['', [Validators.minLength(2), Validators.maxLength(16)]],
      shortUrlPreview: [{ value: 'https://Otrim.com/', disabled: true }],
    });
  }

  onSubmit(): void {
    if (this.shortUrlForm.valid) {
      this.isLoading = true;
      this.otrimFacade
        .trimSingleLink({
          ...this.shortUrlForm.getRawValue(),
          spamUrl: false,
          isEnabled: false,
        })
        .subscribe(
          (res) => {
            // todo: use data in the success modal
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
            console.error(error);
          }
        );
    } else {
      console.log('Form is invalid');
    }
  }
}
