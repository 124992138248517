export const environment = {
  publicPath:'https://o-suite-trim-dev.onfusiondemo.cc',
  COUNTRY_SVG_URL: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/',
  production: false,
  apiUrl: 'https://oes-api.onpassive.com/',
  DEFAULT_KEY: '*$_0x8d91f7i*[^',
  otrimApiUrl: 'https://oes-api-dev.onfusiondemo.cc/otrim/',
  baseLink: 'https://oes-api-dev.onfusiondemo.cc/otrim/',
  bearerToken:
    'eyJhbGciOiJIUzUxMiJ9.eyJmbmFtZSI6InNhYmFoIiwic3ViIjoic2FiYWg5OUBvbmZ1c2lvbnQubmV0IiwibG5hbWUiOiJ0ciIsInByb2R1Y3QiOiJvYnMiLCJzdWJzY3JpYmVkX3Byb2R1Y3RzIjpbXSwiaXNzIjoiaHR0cHM6Ly9vZXMtYXBpLWRldi5vbmZ1c2lvbmRlbW8uY2Mvb2VzdXNlci9sb2dpbiIsInNjb3BlcyI6W3siYXV0aG9yaXR5IjoidXNlciJ9XSwiZXhwIjoxNzMzMDgyNTAxLCJjdXN0YWNjaWQiOjM2MTk4NDcsImlhdCI6MTczMzAzOTMwMSwianRpIjoiOGU0OTFmYjctMDNlYy00ZDEyLThmYTYtYjBhMjNiZThkOTJlIiwiY2lkIjozODgzNjE1fQ.2FBUHccdE4sDLf1VFsJvTMrsl8wsmmdUkNUYQJhFXk9DchrlmnJCkrs_OtUmI27GYV3RAzqcpWPU4iiTLMZhJQ',
};
